import Constants from "./constants";

// styles
var styles = {
  bannerImage: {
    fontSize: 45,
    marginTop: -7,
    height: '100%',
    fontWeight: 400,
  }, 
  chase: {
    color: Constants.colors.red,
    fontWeight: 400,
  },
};

var Navbar = [
  {
    id: "",
    of:
      <div style={styles.bannerImage}><span style={styles.chase}>ize</span>trio</div>,
  },
  {
    id: "Bio",
    of: "About Us",
  },
  {
    id: "Calendar",
    of: "Calendar",
  },
  {
    id: "Recordings",
    of: "Recordings",
  },
  {
    id: "Donate",
    of: "Donate",
  },
   {
    id: "Contact",
    of: "Contact",
  },
  // {
  //   id: "FYC",
  //   of: "For Your Grammy® Consideration",
  // },
];

export default Navbar;