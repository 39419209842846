import Constants from "./helper/constants";
import Img from "./helper/Img"
import CustomLink from './helper/customlink';
import MediaQuery from 'react-responsive';
import CenterTextDiv from "./helper/centerTextDiv";
import BgImg from "./helper/bgImg";
import ProduceContent from './helper/functions';

import { useSelector, useDispatch } from 'react-redux';

var styles = {
  contact: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    paddingTop: (Constants.headerHeight + 30),
    color: Constants.colors.white,
    textAlign: 'center',
  },
  contactMobile: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    marginTop: -1 * (Constants.headerHeight + Constants.footerHeight),
    backgroundColor: Constants.colors.white,
    color: Constants.colors.black,
    textAlign: 'center',
  },
  mobileImageContainer: {
    paddingTop: Constants.headerHeight + Constants.footerHeight,
  },
  contactImage: {
    transition: 'all .1s ease-in',
    WebkitTransition: 'all .1s ease-in',
  },
};


const Contact = () => {
  function createEmailText() {
    return (
      <div style={{paddingRight: 30, paddingLeft: 30}}>
        <p>
          To contact us for booking, teaching, and all other inquiries, please email Chase at
          <CustomLink 
            of={' chasemorrin@gmail.com'} 
            url={'mailto:chasemorrin@gmail.com'}
            style={{
              color: Constants.colors.gray}}
            hoverStyle={{
              color: 'gray'}}/>
        </p>
      </div>
    );
  }

  function createSubscribeText() {
    return (
        <div style={{height: 360, display: 'flex', justifyContent: 'center'}}>
          <iframe src={'https://dashboard.mailerlite.com/forms/756564/139377595160463060/share'} style={{border: 'none', width: 340, height: 362, overflow: 'hidden'}}></iframe>
        </div>
    );
  }


  return (
    <>
      <MediaQuery minWidth={800}>
        <div style={styles.contact}>
          <CenterTextDiv centerHeaderContent={'CONTACT'}/>
          <BgImg darken={.5}  src={'img/izetrio/trio_performance.jpg'}/> 
          {createEmailText()}  
          <div style={{height: 40}}></div>
          <CenterTextDiv centerHeaderContent={'Follow us by Email'}/>
          {createSubscribeText()}
          <div style={{height: 40}}></div>
          {/*<CenterTextDiv centerHeaderContent={'Follow on Instagram'}/>
          {createInstaText()}  */}
          <div style={{height: 40}}></div>
        </div>
      </MediaQuery> 
      <MediaQuery maxWidth={800}>
        <div style={styles.contactMobile}>
          <div style={styles.mobileImageContainer}>
            <Img style={{opacity: 1}} src={'img/izetrio/trio_performance.jpg'}/>
            <div style={{height: 20}}></div>
            <CenterTextDiv centerHeaderContent={'Contact'}/>
            {createEmailText()} 
            <div style={{height: 20}}></div>
            <CenterTextDiv centerHeaderContent={'Follow us by Email'}/>
            {createSubscribeText()} 
            <div style={{height: 30}}></div>
           {/* <CenterTextDiv centerHeaderContent={'Follow on Instagram'}/>
            <div style={{height: 30}}></div>*/}
           {/* {createInstaText()} 
            <div style={{height: 30}}></div>*/}
          </div>
        </div>
      </MediaQuery> 
    </>
  );
};

export default Contact;