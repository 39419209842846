import Constants from "./helper/constants";
import $ from "jquery";
import ProduceContent from './helper/functions';
import ImgLink from './helper/imgLink'
import CenterTextDiv from "./helper/centerTextDiv";
import MediaQuery from 'react-responsive';
import { useEffect } from 'react';
import { useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { saveRecordingData } from './redux/sitedataSlice';

var maxPageWidth = 1100;

var styles = {
  recordings: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  albumPage: {
    maxWidth: maxPageWidth,
    paddingLeft: Constants.PAD,
    paddingRight: Constants.PAD,
  },
  topContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  albums: {
    flex: '8 0 300px',
    width: '100%',
  },
  albumsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
    flexGrow: 0,
    marginLeft: -Constants.PAD/2,
    marginRight: -Constants.PAD/2,
  },
  albumTile: {
    flex: '1 0 250px',
    maxWidth: '100%',
    marginLeft: Constants.PAD/2,
    marginRight: Constants.PAD/2,
    marginBottom: Constants.PAD,
    fontSize: '75%',
    lineHeight: '140%',
  },
  albumTileMobile: {
    flex: '1 0 150px',
    maxWidth: '100%',
    marginLeft: Constants.PAD/2,
    marginRight: Constants.PAD/2,
    marginBottom: Constants.PAD,
    fontSize: '75%',
    lineHeight: '140%',
  },
};

const recordingKeys = {
  spreadsheetId: '1UPAyP-KzH896bPYxsGi1ePkEP0pr4ataHCtBoZ9l-z4',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

const RecordingsDefault = () => {
  const recordingDataGlobal = useSelector((state) => state.sitedata.recordingData);
  const dispatch = useDispatch();

  const [info, setInfo] = useState(null);

  useEffect(() => {
    if (recordingDataGlobal) {
      return;
    }

    $.get(
        'https://sheets.googleapis.com/v4/spreadsheets/'
        + recordingKeys.spreadsheetId
        + '/values/A3:I30' 
        + '?key='
        + recordingKeys.PublicApiKey,
      function(result) {
      if (true) {
        setInfo(result.values);
        dispatch(saveRecordingData(result.values));
      }
    });    
  }, []);

  function makeVideoCell(videoInfo) {
    if (!videoInfo) {
      return null;
    }
    if (!videoInfo[0]) {
      return null;
    }

    var name = videoInfo[0];
    var url = videoInfo[1]; 
    var description = videoInfo[2];
    var link = null;

    return (
      <span style={{width: '100%', paddingBottom: 20}}>
        {ProduceContent('videobox', videoInfo[0], null, videoInfo[1], null, videoInfo[2], maxPageWidth)}
      </span>
    );
  }

  function makeAlbumCell(album) {
    if (!album) {
      return null;
    }
    if (!album[5]) {
      return null;
    }
    var name = album[5];
    var image = album[6];
    var link = album[7];
    var description = album[8];

    var hoverContent = 
      <div style={{position: 'relative'}}>
        <h1 style={{textAlign: 'center'}}>{name}</h1>
        <p style={{paddingLeft: Constants.PAD, paddingRight: Constants.PAD, fontSize: '85%'}}>{description}</p>
      </div>;

    //mobile
    if (window.innerWidth < 500) {
      return (
      <div style={styles.albumTileMobile}>
        <ImgLink 
          imgSrc={image}
          to={link}
          style={{position: 'relative', width: '100%'}}
          content={<div style={{position: 'relative'}}><h1 style={{textAlign: 'center'}}>{name}</h1></div>}/>
      </div>
    );
    }
    //desktop
    return (
      <div style={styles.albumTile}>
        <ImgLink 
          imgSrc={image}
          to={link}
          style={{position: 'relative', width: '100%'}}
          content={hoverContent}
          tag={['click to view details or purchase']}/>
      </div>
    );
  }

  function makeEmptyAlbumCell(album) {
    return (
      <div style={window.innerWidth < 500 ? styles.albumTileMobile : styles.albumTile}>
      </div>
    );
  }

  var albumList = null;
  var videos = null;
  if (recordingDataGlobal) {
    albumList = recordingDataGlobal.map(makeAlbumCell);
    videos = recordingDataGlobal.map(makeVideoCell);
  } else if (info) {
    albumList = info.map(makeAlbumCell);
    videos = info.map(makeVideoCell);
  } else {
    return null;
  }

  if (albumList.length > 0) {
    albumList = albumList.filter(function (e) {return e != null;});
    albumList.push(makeEmptyAlbumCell());
    albumList.push(makeEmptyAlbumCell());
    albumList.push(makeEmptyAlbumCell());
    albumList.push(makeEmptyAlbumCell());
  }

  return (
    <div className="pageContainer" style={{minHeight: window.innerHeight - Constants.footerHeight}}>
      <MediaQuery minWidth={800}>
        <div style={{height: Constants.headerHeight}}></div>
      </MediaQuery>
        <div style={{height: 20}}></div>
      <h1>ALBUMS</h1>
      <div style={styles.albumPage}>
        <div style={styles.albums}>
          <MediaQuery maxWidth={800}>
            <CenterTextDiv
              textContent={'Click on the albums below for more infomation, videos, and links to purchase and listen.'}
            />
            <hr/>
          <div style={{height: Constants.PAD}}></div>
          </MediaQuery>
          <div style={styles.albumsContainer}>  
            {albumList}
          </div>
        </div>
      </div>


      <h1>VIDEOS</h1>
      {videos}
    </div> 
  );
  
};

export default RecordingsDefault;