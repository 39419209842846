import $ from 'jquery';
import ProduceContent from '../helper/functions';
import AlbumText from '../data/AlbumsData';
import { useEffect } from 'react';
import { useState } from 'react';

var compKeys = {
  PublicApiKey: '1UPAyP-KzH896bPYxsGi1ePkEP0pr4ataHCtBoZ9l-z4',
};

const AlbumsContentHelper = ({albumPage = null}) => {

  var initialPages = AlbumText.albums.map(function(x){return({Data: null, Page: x.Page});});
  const [pages, setPages] = useState(initialPages);

  useEffect(() => {
    for (let i = 0; i < AlbumText.albums.length; i++) {
      $.get(
          'https://sheets.googleapis.com/v4/spreadsheets/'
          + AlbumText.albums[i].SheetID
          + '/values/A1:F100' 
          + '?key='
          + compKeys.PublicApiKey,
         function(result) {
        if (true) {
          var currentPages = [...pages];
          if (!currentPages[i].Data) {
            currentPages[i].Data = result.values;
            setPages(currentPages);
          }
        }
      });
    }
  }, []);

  // given page, returns the data if it exsists, otherwise null
  function findData(Page) {
    return (pages.find(x => x.Page == Page));
  }

  function news()  {
    var data = findData(albumPage);

    if (!data.Data) {
      return null;
    }

    var backgroundColor = AlbumText.albums.find(x => x.Page == albumPage).BackgroundColor;

    var news = data.Data.map(function(x){return(ProduceContent(x[3], x[0], backgroundColor, x[2], x[4], x[5], 800))});
    var news = news.filter(function (x) {return x != null });
    return (
      <div style={{zIndex: 1, width: '100%', position: 'relative', backgroundColor: backgroundColor}}>
          {news}
      </div>
    );
  }
  
  return (<>{news()}</>);

};

export default AlbumsContentHelper;