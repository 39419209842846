import React from 'react';
import Constants from './helper/constants';
import $ from 'jquery';
import CustomArrowLink from './helper/customArrowLink';
import MediaQuery from 'react-responsive';
import Img from './helper/Img';
import BgImg from './helper/bgImg';
import { useEffect } from 'react';
import { useState } from 'react';
import dayjs from 'dayjs';

import { useSelector, useDispatch } from 'react-redux';
import { saveCalendarData } from './redux/sitedataSlice';

var styles = {
  calendar: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
    marginBottom: -1 * Constants.footerHeight,
    justifyContent: 'space-between',
    position: 'relative',
  },
  calendarBox: {
    marginTop: Constants.headerHeight + Constants.PAD,
    marginBottom: Constants.footerHeight + Constants.PAD,
    maxWidth: 800,
    padding: Constants.PAD,
  },
  title: {
    color: Constants.colors.white,
    textAlign: 'center',
    paddingBottom: 30,
  },
  eventBox: {
    display: 'flex',
    marginBottom: Constants.PAD,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  leftBox: {
    marginRight: 0,
  },
  dateBox: {
    width: 100,
    height: 100,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Constants.colors.gray,
    flexShrink: 0,
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  dateBoxMobile: {
    width: 100,
    height: 100,
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: Constants.colors.gray,
    flexShrink: 0,
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
    float: 'right',
    marginLeft: 10,
  },
  performanceTitle: {
    color: Constants.colors.white,
    fontSize: '125%',
    textAlign: 'left',
  },
  performanceTitleHover: {
    color: Constants.colors.gray,
    fontSize: '125%',
    textAlign: 'left',
  },
  eventText: {
    color: Constants.colors.gray,
    margin: 0,
  },
  dateText: {
    color: Constants.colors.gray,
    margin: 0,
    fontSize: '90%',
  },
  mobile: {
    calendar: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'center',
      width: '100%',
      backgroundColor: Constants.colors.black,
    },
    calendarBox: {
      padding: Constants.PAD,
    },
    title: {
      color: Constants.colors.white,
      textAlign: 'center',
      lineHeight: 1.5,
      marginTop: 0,
      paddingBottom: 30,
    },
  },
};

const keys = {
  calendarID: '557f1qg46s6fehorjr1j13628c@group.calendar.google.com',
  PublicApiKey: 'AIzaSyB9o13p6a_RHvno6fV9wKSri1Y9rFkSzBA',
};

const Calendar = () => {

  const calendarDataGlobal = useSelector((state) => state.sitedata.calendarData);
  const dispatch = useDispatch();

  const [calendarData, setCalendarData] = useState([]);

  useEffect(() => {
    if (calendarDataGlobal) {
      return;
    }

    var cur_time = (new Date()).toISOString();
    $.get(
        'https://www.googleapis.com/calendar/v3/calendars/' 
        + keys.calendarID 
        + '/events?orderBy=startTime&singleEvents=true&timeMin='
        + cur_time
        + '&key=' 
        + keys.PublicApiKey,
        function(result) {
      if (true) {
        setCalendarData(result.items);
        dispatch(saveCalendarData(result.items));
      }
    });
  }, []);

  function createCalendarText(calendarObject){
    function handleDescription (x) {
        if (x.includes("LINK")) {
          x = x.split(" LINK ");
          return (
            <div style={{paddingTop: 5}}>
              <CustomArrowLink 
                arrow
                of={x[0]}
                url={x[1]}
                style={{color: Constants.colors.white}}
                hoverStyle={{color: Constants.colors.gray}}
              />
            </div>
          );
        }
        return (x);
      }

    if (!calendarObject || !calendarObject.start) {
      return null;
    }
    var izeTrioExsists = false;

    var title = calendarObject.summary;
    var startTime  = dayjs(calendarObject.start.dateTime);
    var startDay = startTime.format('dddd');
    var startDate = startTime.format('MMMM D');
    var startTime = startTime.format('h:mm a');
    var location = calendarObject.location;
    var htmlLink = calendarObject.htmlLink;
    var descriptionAndLink = [null, null];
    if (calendarObject.description) {
      descriptionAndLink = calendarObject.description.split("$$");
    }
    var description = descriptionAndLink[0];

    izeTrioExsists = title.includes('Ize') || description.includes('Ize'); // check if Ize event
    if (!izeTrioExsists) {
      return null;
    } 

    description = description.split(' $ ');
    description = description.map(handleDescription);

    if (descriptionAndLink[1] != null) {
      htmlLink = descriptionAndLink[1];
    }
    return (
      <div style={styles.eventBox}>
        <div style={styles.leftBox}>
          <CustomArrowLink 
            of={title}
            url={htmlLink}
            style={styles.performanceTitle}
            hoverStyle={styles.performanceTitleHover}
          />
          <div style={{marginLeft: 15, paddingBottom: 30, paddingTop: 10}}>
            <MediaQuery maxWidth={800}>
              <div style={styles.dateBoxMobile}>
                <p style={styles.dateText}>{startDay}</p>
                <p style={styles.dateText}>{startDate}</p>
                <p style={styles.dateText}>{startTime}</p>
              </div>
            </MediaQuery>
            <p style={styles.eventText}><span style={{color: 'white'}}>Where: </span>{location}</p>
            <div style={{height: 5}}></div>
            <p style={styles.eventText}><span style={{color: 'white'}}>What: </span>{description}</p>
          </div>
        </div>
        <MediaQuery minWidth={800}>
          <div style={styles.dateBox}>
            <p style={styles.dateText}>{startDay}</p>
            <p style={styles.dateText}>{startDate}</p>
            <p style={styles.dateText}>{startTime}</p>
          </div>
        </MediaQuery>
      </div>
    );
  };


  var calendarDataVar = null;
  if (calendarDataGlobal) {
    calendarDataVar = Array.apply(null, Array(calendarDataGlobal.length)).map(function () {});
    for (var i in calendarDataVar) {
      calendarDataVar[i] = createCalendarText(calendarDataGlobal[i]);
    }
  } else if (calendarData) {
    calendarDataVar = Array.apply(null, Array(calendarData.length)).map(function () {});
    for (var i in calendarDataVar) {
      calendarDataVar[i] = createCalendarText(calendarData[i]);
    }
  } else {
    return null;
  }

  if (calendarDataVar.length == 0) {
    calendarDataVar = 
      <p style={{color: Constants.colors.gray, textAlign: 'center'}}>
        There are no current upcoming performances. Check again soon.
      </p>;
  }

  return (
    <>
      <MediaQuery minWidth={800}>
        <div style={styles.calendar}>
          <BgImg src={'img/izetrio/riq_short.jpg'} darken={.7}/>
          <div style={styles.calendarBox}>
            <h1 style={styles.title}>UPCOMING PERFORMANCES</h1>
            {calendarDataVar}
          </div>
        </div>
      </MediaQuery> 
      <MediaQuery maxWidth={800}>
        <div style={styles.mobile.calendar}>
          <Img src={'img/pagephotos/CalendarMobileTop.png'}/> 
          <div style={styles.mobile.calendarBox}>   
            <h1 style={styles.mobile.title}>Upcoming Performances</h1>
            {calendarDataVar}
          </div>
          <Img style={{width: '100%'}} src={'img/pagephotos/CalendarMobileBottom.png'}/>  
        </div>
      </MediaQuery> 
    </>
  );  

};

export default Calendar;
